<template>
    <v-row align="center">
        <ModalNuevaEmpresa :dialog.sync="dialog" :initialize.sync="initTable" :handleModal="handleModal" v-if="dialog"/>
        <ModalEditarEmpresa :dialogEdit.sync="dialogEdit" :dataEditEmpresa.sync="dataEditEmpresa"
        :initialize.sync="initTable" :handleModal="handleEditModal" v-if="dialogEdit">
        </ModalEditarEmpresa>
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva empresa
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar empresa por razon social" single-inline hide-details @keydown.enter="searchCompany">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="empresas" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalEmpresa">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editarEmpresa(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="eliminarEmpresa(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                    </template>

                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip :color="colorstatus(item.activo)" label x-small dark
                            v-text="item.activo ? 'Activo' : 'Inactivo'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import EmpresaDataService from '../../service/catalogo/empresa/empresaDataService'
import ModalNuevaEmpresa from '@/components/empresa/NuevaEmpresa.vue';
import ModalEditarEmpresa from './EditarEmpresa.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';
export default {
    name: "ListaEmpresa",
    components: {
        ModalNuevaEmpresa, ModalEditarEmpresa, Columns
    },
    created() {
        this.initTable();
    },
    data() {
        return {
            empresas: [],
            options: {},
            totalEmpresa: 0,
            search: "",
            loading: false,
            dialog: false,
            dialogEdit: false,
            dataEditEmpresa: [],
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                { text: "Empresa", value: "empresa", sortable: false, groupable: false, show: true },
                { text: "Razon Social", value: "razonSocial", sortable: false, groupable: false, show: true },
                { text: "Direccion (g)", value: "direccion", sortable: false, groupable: false, show: true },
                { text: "Dominio", value: "dominio", sortable: false, groupable: false, show: true },
                { text: "Clave", value: "clave", sortable: false, groupable: false, show: true },
                { text: "RFC", value: "rfc", sortable: false, groupable: false, show: true },
                { text: "Estatus (g)", value: "activo", sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchCompany() {
            this.options.page = 1;
            this.initTable(this.options);
        },
        async initTable( options = {}) {
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            await EmpresaDataService.getallEmpresa(params).then(response => {
                const { rows, totalElements } = response.data;
                this.empresas = rows;
                this.totalEmpresa = totalElements;
            }).catch(error => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener las empresas', text: error, showConfirmButton: false, timer: 3000 });
            }).finally(() => {
                this.loading = false;
            })
        },
        editarEmpresa(item) {
            EmpresaDataService.show(item.id).then(response => {
                this.dataEditEmpresa = response.data;
                this.dialogEdit = true;
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener la empresa', text: err.response.data.message, showConfirmButton: false, timer: 3000 });
            });
        },
        eliminarEmpresa(item) {
            this.$swal({
                title: "Estas seguro?",
                text: "No se podra revertir la decision!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!"
            }).then(result => {
                if (result.isConfirmed) {
                    EmpresaDataService.delete(item.id).then(rsp => {
                        if (rsp.status === 200) {
                            this.initTable();
                            this.$swal.fire({ icon: 'success', title: 'Empresa eliminada', text: rsp.data.message, showConfirmButton: false, timer: 1500 });
                        }
                        if (rsp.status === 400 || rsp.status === 404) {
                            this.$swal.fire({ icon: 'error', title: 'Error al eliminar la empresa', text: rsp.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }).catch(err => {
                        this.$swal.fire({ icon: 'error', title: 'Error!!', text: err.response.data.message, showConfirmButton: false, timer: 3000 });
                    });
                }
            });
        },
        colorstatus(status) { return status ? "success" : "error"; },
        handleModal() { this.dialog = !this.dialog; },
        handleEditModal() { this.dialogEdit = !this.dialogEdit; },

    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTable(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter(h => h.show); },
    }
}
</script>
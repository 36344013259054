import { render, staticRenderFns } from "./NuevaEmpresa.vue?vue&type=template&id=d8a9e42e"
import script from "./NuevaEmpresa.vue?vue&type=script&lang=js"
export * from "./NuevaEmpresa.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
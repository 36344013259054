<template>
    <v-dialog v-model="dialogEdit" persistent transition="expand-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="refBusiness" v-slot="{ invalid, validated }" :immediate="true">
                <v-form name="form" @submit.prevent="updateEmpresa">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar Empresa</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" text type="submit" :disabled="invalid || !validated">
                            <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar cambios</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-text-field label="Nombre de la empresa*" prepend-icon="fas fa-briefcase"
                                    required v-model="dataEditEmpresa.empresa"
                                    :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-text-field label="Razon Social*" prepend-icon="fas fa-user" required
                                    v-model="dataEditEmpresa.razonSocial" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="12" md="5">
                                <v-text-field label="Direccion" hint="Direccion de la empresa" prepend-icon="fas fa-map-marker-alt"
                                persistent-hint v-model="dataEditEmpresa.direccion"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-text-field label="Dominio" hint="Dominio de la empresa"  prepend-icon="fas fa-globe-americas"
                                persistent-hint v-model="dataEditEmpresa.dominio"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <ValidationProvider rules="required|min:12|max:13" v-slot="{ errors, valid}" :immediate="true">
                                    <v-text-field label="RFC*" hint="Registro federal de contribuyentes"  prepend-icon="fas fa-id-card"
                                    persistent-hint clearable required v-model="dataEditEmpresa.rfc"
                                    :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field label="Clave" hint="Clave de la empresa"  prepend-icon="fas fa-keyboard"
                                persistent-hint readonly disabled v-model="dataEditEmpresa.clave"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea class="mx-2" label="Descripcion adicional" rows="1" hint="Informacion extra de la Empresa.."
                                prepend-icon="fas fa-comment" persistent-hint v-model="dataEditEmpresa.descripcion"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-checkbox  class="mx-2" v-model="dataEditEmpresa.activo"
                                :hint="(dataEditEmpresa.activo == false ? 'Habilitar ' : 'Desactivar ') +'empresa en el sistema'"
                                persistent-hint :label="dataEditEmpresa.activo ? 'Empresa Activa': 'Empresa inactiva'">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <small>* Indica son campos requeridos</small>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import EmpresaDataService from '@/service/catalogo/empresa/empresaDataService';
export default {
    name: "EditarEmpresa",
    props: {
        dialogEdit: { default: false },
        handleModal: { type: Function },
        dataEditEmpresa: {},
        initialize: { type: Function }
    },
    methods: {
        updateEmpresa(){
            this.$refs.refBusiness.validate().then( isValid => {
                if( isValid ){
                    EmpresaDataService.update( this.dataEditEmpresa ).then( rsp => {
                        if (rsp.status == 200) {
                            this.$refs.refBusiness.reset();
                            this.handleModal();
                            this.$swal.fire({ icon : 'success', title : 'Empresa actualizada correctamente', text: rsp.data.message, showConfirmButton : false, timer : 1500 });
                            this.initialize();
                        }
                        if (rsp.status == 400 || rsp.status == 404){
                            this.$swal.fire({ icon : 'error', title : 'Error al actualizar la empresa', text: rsp.data.message, showConfirmButton : false, timer : 1500 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon : 'error', title : 'Error al actualizar la empresa', text: err.response.data.message, showConfirmButton : false, timer : 1500 });
                    });
                }
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Revisar datos', text: err.message, showConfirmButton: false, timer: 2500 });
            })
        }
    }
}
</script>
<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="obsEmpresa" v-slot="{ invalid, validated }">
                <v-form name="form" @submit.prevent="saveEmpresa">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Nuevo Empresa</v-toolbar-title>
                        <v-spacer></v-spacer>
        
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar empresa
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                    <v-text-field label="Nombre de la empresa*" prepend-icon="fas fa-briefcase"
                                    required clearable v-model="empresa.empresa"
                                    :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        
                            <v-col cols="12" sm="6" md="4">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                    <v-text-field label="Razon Social*" prepend-icon="fas fa-user" required clearable 
                                    v-model="empresa.razonSocial" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="12" md="5">
                                <v-text-field label="Direccion" hint="Direccion de la empresa"  prepend-icon="fas fa-map-marker-alt"
                                persistent-hint v-model="empresa.direccion"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-text-field label="Dominio" hint="Dominio de la empresa"  prepend-icon="fas fa-globe-americas"
                                persistent-hint v-model="empresa.dominio"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <ValidationProvider rules="required|min:12|max:13" v-slot="{ errors, valid}">
                                    <v-text-field label="RFC*" hint="Registro federal de contribuyentes"  prepend-icon="fas fa-address-card"
                                    persistent-hint clearable required v-model="empresa.rfc"
                                    :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <ValidationProvider rules="required|max:5" v-slot="{ errors, valid}">
                                    <v-text-field label="Clave*" hint="Clave de la empresa"  prepend-icon="fas fa-keyboard"
                                    persistent-hint clearable required v-model="empresa.clave"
                                    :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea class="mx-2" label="Descripcion adicional" rows="1" hint="Informacion extra de la empresa.."
                                prepend-icon="fas fa-comment" persistent-hint clearable v-model="empresa.descripcion"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-checkbox  class="mx-2" v-model="empresa.activo" persistent-hint
                                :hint="(empresa.activo == false ? 'Habilitar ' : 'Desactivar ') +'empresa en el sistema'"
                                :label="empresa.activo == true ? 'Empresa Activa': 'Empresa inactiva'">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <small>* Indica son campos requeridos</small>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import EmpresaDataService from '../../service/catalogo/empresa/empresaDataService';
export default {
    name: "NuevaEmpresa",
    props: {
        dialog: { default: false },
        initialize: { type: Function },
        handleModal: { type: Function }
    },
    data () {
        return {
            empresa: {
                activo:         true,
                clave:          "",
                direccion:      "",
                dominio:        "",
                empresa:        "",
                logotipo:       null,
                razonSocial:    "",
                rfc:            "",
                descripcion:    ""
            }
        }
    },
    methods: {
        resetData(){
            this.$refs.obsEmpresa.reset();
            this.handleModal();
        },
        saveEmpresa(){
            this.$refs.obsEmpresa.validate().then( isValid => {
                if( isValid  ){
                    EmpresaDataService.create( this.empresa ).then( rsp => {
                        if (rsp.status === 200 || rsp.status === 201) {
                            this.resetData();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Genial!!', text: rsp.data.message, showConfirmButton: false, timer: 3500 });
                        }
                        if( rsp.status === 401 || rsp.status === 417 || rsp.status === 400 ){
                            this.$swal.fire({ position: 'top-end', icon: 'error', title: rsp.data.message, showConfirmButton: false, timer: 3500 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Empresa no registrado', text: err, showConfirmButton: false, timer: 3500 });
                    });
                }
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', text: err.message, title: 'Por favor verifique que los campos esten llenos correctamente', showConfirmButton: false, timer: 3500 });
            });
        }
    }
}
</script>